
.application-result {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  margin: 20px auto;
}

.application-result-div {
  display: block;
  margin-bottom: 5px;
}




.application-icon-wrap {
  /* padding: 4px 6px 4px 6px; */
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}


.application-bottom-row {
  display: flex;
  flex-wrap: wrap; /* Allow divs to wrap to the next line */
  justify-content: center; /* Center the divs horizontally */
  max-width: 100%; /* Set a maximum width as needed */
  margin: 0 auto; /* Center the container horizontally */
}

.application-box {
  width: 230px;
  height: 210px;
  /* border: 1px solid #000; */
  margin: 5px;
  margin-bottom: 50px;
}



.app-feature-card {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
   /* position: relative; */ 
}
.app-feature-card2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
   /* position: relative; */
}
.app-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
   /* position: relative; */
}
.app-heading {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 20px;
    /* position: relative; */
}



@media (max-width: 2560px)
{
  .application-m-48-coding {
    /* position: absolute; */
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 100;
    height: 100px;
  }
  .app-feature-title-style-as-headline-h-3-mixed-case-lorem-ipsum {
    color: var(--light-theme-headings-headlines, #000000);
    text-align: center;
    font: 500 15px/100% "NVIDIA Sans", sans-serif;
     /* position: relative; */
    align-self: stretch;
    
  }
  
  
}

@media (max-width: 1360px)
{
  .application-m-48-coding {
    /* position: absolute; */
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 70;
    height: 70px;
  }
  .app-feature-title-style-as-headline-h-3-mixed-case-lorem-ipsum {
    color: var(--light-theme-headings-headlines, #000000);
    text-align: center;
    font: 600 17px/100% "NVIDIA Sans", sans-serif;
     /* position: relative; */
    align-self: stretch;
    
  }
  
 
}

@media (max-width: 1024px)
{
  .application-m-48-coding {
    /* position: absolute; */
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 60;
    height: 60px;
  }
  .app-feature-title-style-as-headline-h-3-mixed-case-lorem-ipsum {
    color: var(--light-theme-headings-headlines, #000000);
    text-align: center;
    font: 600 17px/100% "NVIDIA Sans", sans-serif;
     /* position: relative; */
    align-self: stretch;
    
  }
  
  
}

@media (max-width: 640px)
{
  .application-m-48-coding {
    /* position: absolute; */
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 50;
    height: 50px;
  }
  .app-feature-title-style-as-headline-h-3-mixed-case-lorem-ipsum {
    color: var(--light-theme-headings-headlines, #000000);
    text-align: center;
    font: 600 17px/100% "NVIDIA Sans", sans-serif;
     /* position: relative; */
    align-self: stretch;
    
  }
  
  
}
.app-description {
  color: var(--light-theme-text, #1a1a1a);
  text-align: center;
  font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
   /* position: relative; */
  align-self: stretch;
  margin-bottom: 10px;
}
.app-feature-title-style-as-headline-h-3-mixed-case-lorem-ipsum {
  color: var(--light-theme-headings-headlines, #000000);
  text-align: center;
  font: 600 17px/100% "NVIDIA Sans", sans-serif;
   /* position: relative; */
  align-self: stretch;
  
}