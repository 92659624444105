.loading-spinner {
    margin-top: 8rem;
  }
  
  .formsubmitbtn{
    float: right;
    margin-right: 10px;
  }
  .hubtagfieldblocksection {
    margin-bottom: 10px !important;
   }
  .hubtagfieldblocksection > div{
    margin-bottom: 0px !important;
   }
   .error{
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
    white-space: normal;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-break: normal;
    color: #F03232;
   }
  
   .hubtagfieldh4{
    margin-bottom: 4px !important;
    margin-top: 5px !important;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
    white-space: normal;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-break: normal;
    /* color: #FAFAFA; */
   }
   .hubtagfieldspan{
    color: #F03232;
   }

   .hubbanner{
    visibility: collapse;
    display: none;
   }
   .hubtagfieldtext{
    margin-bottom: 5px !important;
    margin-top: 5px !important;
   }
.modelstyle
{
    position: "absolute";
    top: "50%";
    left: "50%";
    transform: "translate(-50%, -50%)";
    width: 400;
    border: "2px solid #000";

}
