@media (max-width: 1360px)
{
    .signoutbox {
        /* margin: 0; */
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;        
        height: 250px;
    }

.main-panel.bright {
    background-color: #fff;
}
.main-panel-heading {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 12px;
}
.main-panel-message {
    font-size: 14px;
    margin-bottom: 12px;
}
.main-panel {
    max-width: 648px;
    min-width: 480px;
    padding: 32px 48px 24px;
    padding: 0;
 
    
  justify-content: center;
  align-items: center;  
  background-color: #f0f0f0; 
}
.centered {
    text-align: center;
}
.main-panel.bright {
    background-color: #fff;
}
}

@media (max-width: 2560px)
{
    .signoutbox {
        /* margin: 0; */
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
        
        height: 300px;
    }

.main-panel.bright {
    background-color: #fff;
}
.main-panel-heading {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 12px;
}
.main-panel-message {
    font-size: 14px;
    margin-bottom: 12px;
}
.main-panel {
    max-width: 648px;
    min-width: 480px;
    padding: 32px 48px 24px;
    padding: 0;
 
    
  justify-content: center;
  align-items: center;  
  background-color: #f0f0f0; 
}
.centered {
    text-align: center;
}
.main-panel.bright {
    background-color: #fff;
}
}
@media (max-width: 1024px)
{
    .signoutbox {
        /* margin: 0; */
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
        
        height: 200px;
    }

.main-panel.bright {
    background-color: #fff;
}
.main-panel-heading {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 12px;
}
.main-panel-message {
    font-size: 14px;
    margin-bottom: 12px;
}
.main-panel {
    max-width: 648px;
    min-width: 480px;
    padding: 32px 48px 24px;
    padding: 0;
 
    
  justify-content: center;
  align-items: center;  
  background-color: #f0f0f0; 
}
.centered {
    text-align: center;
}
.main-panel.bright {
    background-color: #fff;
}
}

@media (max-width: 650px)
{
    .signoutbox {
        /* margin: 0; */
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
        
        height: 150px;
    }
.main-panel.bright {
    background-color: #fff;
}
.main-panel-heading {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 12px;
}
.main-panel-message {
    font-size: 14px;
    margin-bottom: 12px;
}
.main-panel {
    max-width: 648px;
    min-width: 480px;
    padding: 32px 48px 24px;
    padding: 0;
 
    
  justify-content: center;
  align-items: center;  
  background-color: #f0f0f0; 
}
.centered {
    text-align: center;
}
.main-panel.bright {
    background-color: #fff;
}
}