@media (max-width:2560px)
{
    .footer,
    .footer * {
      box-sizing: border-box;
    }
    .footer {
      display: flex;
      flex-direction: column;
      gap: 0px;
      align-items: flex-start;
      justify-content: flex-start;
      
    }
    .page-footer {
      background: var(--grayscale-black, #000000);
      padding: 60px 30px 60px 30px;
      display: flex;
      flex-direction: column;
      gap: 60px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width:100%;
      
    }
    .follow-links {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-shrink: 0;
      width: 76%;
      
    }
    .subscribe {
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .page-footer-frame-39 {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .communication-envelope-line {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .sign-up-for-nvidia-news {
      color: var(--grayscale-white, #ffffff);
      text-align: left;
      font: var(
        --desktop-button-text-large,
        700 18px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .page-footer-cta-button-primary {
      background: var(--brand-nvidia-green, #76b900);
      padding: 12px 14px 12px 14px;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      
    }
    .page-footer-button-text {
      color: var(--grayscale-black, #000000);
      text-align: center;
      font: var(
        --laptop-button-text-large,
        700 16px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .social {
      padding: 11px 0px 11px 0px;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      flex-shrink: 0;
      
    }
    .follow-nvidia {
      color: var(--grayscale-gray-300, #cccccc);
      text-align: left;
      font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
      
    }
    .social-icons {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .icon-font-awesome-free-brands-f-facebook-f {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-t-twitter {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-l-linkedin-in {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-i-instagram {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-y-youtube {
      flex-shrink: 0;
      
      overflow: visible;
    }
     
}
@media (max-width: 1360px)
{
    .footer,
    .footer * {
      box-sizing: border-box;
    }
    .footer {
      display: flex;
      flex-direction: column;
      gap: 0px;
      align-items: flex-start;
      justify-content: flex-start;
      
    }
    .page-footer {
      background: var(--grayscale-black, #000000);
      padding: 30px 30px 30px 30px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width:100%;
      
    }
    .follow-links {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-shrink: 0;
      width: 76%;
      flex-wrap: wrap; /* This enables wrapping */
    }
    .subscribe {
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .page-footer-frame-39 {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .communication-envelope-line {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .sign-up-for-nvidia-news {
      color: var(--grayscale-white, #ffffff);
      text-align: left;
      font: var(
        --desktop-button-text-large,
        700 18px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .page-footer-cta-button-primary {
      background: var(--brand-nvidia-green, #76b900);
      padding: 12px 14px 12px 14px;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      
    }
    .page-footer-button-text {
      color: var(--grayscale-black, #000000);
      text-align: center;
      font: var(
        --laptop-button-text-large,
        700 16px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .social {
      padding: 11px 0px 11px 0px;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      flex-shrink: 0;
      
    }
    .follow-nvidia {
      color: var(--grayscale-gray-300, #cccccc);
      text-align: left;
      font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
      
    }
    .social-icons {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .icon-font-awesome-free-brands-f-facebook-f {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-t-twitter {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-l-linkedin-in {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-i-instagram {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-y-youtube {
      flex-shrink: 0;
      
      overflow: visible;
    }
     
}
@media (max-width:1024px)
{
    .footer,
    .footer * {
      box-sizing: border-box;
    }
    .footer {
      display: flex;
      flex-direction: column;
      gap: 0px;
      align-items: flex-start;
      justify-content: flex-start;
      
    }
    .page-footer {
      background: var(--grayscale-black, #000000);
      padding: 30px 30px 30px 30px;
      display: flex;
      flex-direction: column;
      gap: 60px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width:100%;
      
    }
    .follow-links {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-shrink: 0;
      width: 76%;
      
    }
    .subscribe {
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .page-footer-frame-39 {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .communication-envelope-line {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .sign-up-for-nvidia-news {
      color: var(--grayscale-white, #ffffff);
      text-align: left;
      font: var(
        --desktop-button-text-large,
        700 18px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .page-footer-cta-button-primary {
      background: var(--brand-nvidia-green, #76b900);
      padding: 12px 14px 12px 14px;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      
    }
    .page-footer-button-text {
      color: var(--grayscale-black, #000000);
      text-align: center;
      font: var(
        --laptop-button-text-large,
        700 16px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .social {
      padding: 11px 0px 11px 0px;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      flex-shrink: 0;
      
    }
    .follow-nvidia {
      color: var(--grayscale-gray-300, #cccccc);
      text-align: left;
      font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
      
    }
    .social-icons {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .icon-font-awesome-free-brands-f-facebook-f {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-t-twitter {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-l-linkedin-in {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-i-instagram {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-y-youtube {
      flex-shrink: 0;
      
      overflow: visible;
    }
     
}
@media (max-width:640px)
{
    .footer,
    .footer * {
      box-sizing: border-box;
    }
    .footer {
      display: flex;
      flex-direction: column;
      gap: 0px;
      align-items: flex-start;
      justify-content: flex-start;
      
    }
    .page-footer {
      background: var(--grayscale-black, #000000);
      padding: 60px 30px 60px 30px;
      display: flex;
      flex-direction: column;
      gap: 60px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width:100%;
      
    }
    .follow-links {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-shrink: 0;
      width:76%;
      
    }
    .subscribe {
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .page-footer-frame-39 {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .communication-envelope-line {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .sign-up-for-nvidia-news {
      color: var(--grayscale-white, #ffffff);
      text-align: left;
      font: var(
        --desktop-button-text-large,
        700 18px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .page-footer-cta-button-primary {
      background: var(--brand-nvidia-green, #76b900);
      padding: 12px 14px 12px 14px;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      
    }
    .page-footer-button-text {
      color: var(--grayscale-black, #000000);
      text-align: center;
      font: var(
        --laptop-button-text-large,
        700 16px/125% "NVIDIA Sans",
        sans-serif
      );
      
    }
    .social {
      padding: 11px 0px 11px 0px;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      flex-shrink: 0;
      
    }
    .follow-nvidia {
      color: var(--grayscale-gray-300, #cccccc);
      text-align: left;
      font: var(--desktop-text-medium, 400 15px/166.7% "NVIDIA Sans", sans-serif);
      
    }
    .social-icons {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-shrink: 0;
      
    }
    .icon-font-awesome-free-brands-f-facebook-f {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-t-twitter {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-l-linkedin-in {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-i-instagram {
      flex-shrink: 0;
      
      overflow: visible;
    }
    .icon-font-awesome-free-brands-y-youtube {
      flex-shrink: 0;
      
      overflow: visible;
    }
     
}