.global-footer-item a
{
  text-decoration: none;
}
.global-footer-item {
  display: flex;
  flex-direction: row;
  
  align-items: center;
  /* justify-content: flex-start; */
  flex-shrink: 0;
  /* position: relative; */
}
.global-footer-links {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  flex-wrap: wrap; /* This enables wrapping */
  /* position: relative; */
}
.equal-space {
  margin-right: 5px; /* Adjust the value to control the spacing */
  margin-left: 5px; /* Adjust the value to control the spacing */
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  color: var(--grayscale-gray-500, #808080);
}
@media (max-width: 2560px)
{
  
  .global-footer-global-footer {
    background: var(--light-theme-background-default, #ffffff);
    padding: 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width:100%;
    /* position: absolute; */
    left: 0px;
    /* top: 480px; */
  }
  .global-footer-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 70%;
    /* position: relative; */
  }
  .global-footer-branding {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    flex-shrink: 0;
    height: 20.5px;

  }
  .global-footer-logo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;

  }
  .global-footer-logo2 {
    flex-shrink: 0;
    width: 161px;
    height: 30px;
    /* position: relative; */
  }
  .global-footer-wordmark {
    /* position: absolute; */
    left: 52.25px;
    top: 5.65px;
    overflow: visible;
  }
  .global-footer-eye-mark {
    /* position: absolute; */
    left: 0.09px;
    top: 0.02px;
    overflow: visible;
  }
  .global-footer-country {
    color: var(--grayscale-gray-300, #cccccc);
    text-align: center;
    font: var(
      --desktop-button-text-large,
      700 18px/125% "NVIDIA Sans",
      sans-serif
    );
    /* position: relative; */
  }
  .global-footer-container2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 100%;
    /* position: relative; */
  }
  
  .global-footer-privacy-policy {
    color: var(--grayscale-gray-500, #808080);
    text-align: center;
    font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
    /* position: relative; */
  }

  .global-footer-manage-my-privacy {
    color: var(--grayscale-gray-500, #808080);
    text-align: center;
    font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
    /* position: relative; */
  }
  .global-footer-legal {
    color: var(--grayscale-gray-500, #808080);
    text-align: center;
    font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
    /* position: relative; */
  }
  .global-footer-accessibility {
    color: var(--grayscale-gray-500, #808080);
    text-align: center;
    font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
    /* position: relative; */
  }
  .global-footer-product-security {
    color: var(--grayscale-gray-500, #808080);
    text-align: center;
    font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
    /* position: relative; */
  }
  .global-footer-contact {
    color: var(--grayscale-gray-500, #808080);
    text-align: center;
    font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
    /* position: relative; */
  }
  .copyright-2022-nvidia-corporation {
    color: var(--grayscale-gray-500, #808080);
    text-align: center;
    font: var(--laptop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
    /* position: relative; */
  }
  
  
  
  
}

@media (max-width: 1360px)
{
  

.global-footer-global-footer {
  background: var(--light-theme-background-default, #ffffff);
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width:100%;
  /* position: absolute; */
  left: 0px;
  /* top: 480px; */
}
.global-footer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 70%;
  /* position: relative; */
}
.global-footer-branding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 20.5px;
  max-width:100% ;
  /* position: relative; */
}
.global-footer-logo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  /* position: relative; */
}
.global-footer-logo2 {
  flex-shrink: 0;
  width: 161px;
  height: 30px;
  /* position: relative; */
}
.global-footer-wordmark {
  /* position: absolute; */
  left: 52.25px;
  top: 5.65px;
  overflow: visible;
}
.global-footer-eye-mark {
  /* position: absolute; */
  left: 0.09px;
  top: 0.02px;
  overflow: visible;
}
.global-footer-country {
  color: var(--grayscale-gray-300, #cccccc);
  text-align: center;
  font: var(
    --desktop-button-text-large,
    700 18px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.global-footer-container2 {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width:100% ;
  /* position: relative; */
}


.global-footer-privacy-policy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.global-footer-manage-my-privacy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-legal {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-accessibility {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-product-security {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-contact {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.copyright-2022-nvidia-corporation {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}



}
@media (max-width: 1024px)
{
 
.global-footer-global-footer {
  background: var(--light-theme-background-default, #ffffff);
  padding: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width:100%;
  /* position: absolute; */
  left: 0px;
  /* top: 480px; */
}
.global-footer-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 70%;
  /* position: relative; */
}
.global-footer-branding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 20.5px;
  /* position: relative; */
}
.global-footer-logo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  /* position: relative; */
}
.global-footer-logo2 {
  flex-shrink: 0;
  width: 161px;
  height: 30px;
  /* position: relative; */
}
.global-footer-wordmark {
  /* position: absolute; */
  left: 52.25px;
  top: 5.65px;
  overflow: visible;
}
.global-footer-eye-mark {
  /* position: absolute; */
  left: 0.09px;
  top: 0.02px;
  overflow: visible;
}
.global-footer-country {
  color: var(--grayscale-gray-300, #cccccc);
  text-align: center;
  font: var(
    --desktop-button-text-large,
    700 18px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.global-footer-container2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  flex-wrap: wrap; /* This enables wrapping */
  /* position: relative; */
}


.global-footer-privacy-policy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.global-footer-manage-my-privacy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-legal {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-accessibility {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-product-security {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-contact {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.copyright-2022-nvidia-corporation {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
 
}
@media (max-width: 640px)
{
 
.global-footer-global-footer {
  background: var(--light-theme-background-default, #ffffff);
  padding: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width:100%;
  flex-wrap: wrap;
  /* position: absolute; */
  left: 0px;
  /* top: 480px; */
}
.global-footer-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 70%;
  flex-wrap: wrap;
  /* position: relative; */

}
.global-footer-branding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 20.5px;
  flex-wrap: wrap;
  width:100%;
  /* position: relative; */
}
.global-footer-logo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  /* position: relative; */
}
.global-footer-logo2 {
  flex-shrink: 0;
  width: 190px;
  height: 30px;
  /* position: relative; */
}
.global-footer-wordmark {
  /* position: absolute; */
  left: 52.25px;
  top: 5.65px;
  overflow: visible;
}
.global-footer-eye-mark {
  /* position: absolute; */
  left: 0.09px;
  top: 0.02px;
  overflow: visible;
}
.global-footer-country {
  color: var(--grayscale-gray-300, #cccccc);
  text-align: center;
  font: var(
    --desktop-button-text-large,
    700 18px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.global-footer-container2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
 
  width: 100%;
  /* position: relative; */
}

.global-footer-privacy-policy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.global-footer-manage-my-privacy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-legal {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-accessibility {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-product-security {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-contact {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.copyright-2022-nvidia-corporation {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
 
}


.global-footer-global-footer {
  background: var(--light-theme-background-default, #ffffff);
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width:100%;
  /* position: absolute; */
  left: 0px;
  /* top: 480px; */
}
.global-footer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 70%;
  /* position: relative; */
}
.global-footer-branding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 20.5px;
  max-width:100% ;
  /* position: relative; */
}
.global-footer-logo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  /* position: relative; */
}
.global-footer-logo2 {
  flex-shrink: 0;
  width: 161px;
  height: 30px;
  /* position: relative; */
}
.global-footer-wordmark {
  /* position: absolute; */
  left: 52.25px;
  top: 5.65px;
  overflow: visible;
}
.global-footer-eye-mark {
  /* position: absolute; */
  left: 0.09px;
  top: 0.02px;
  overflow: visible;
}
.global-footer-country {
  color: var(--grayscale-gray-300, #cccccc);
  text-align: center;
  font: var(
    --desktop-button-text-large,
    700 18px/125% "NVIDIA Sans",
    sans-serif
  );
  /* position: relative; */
}
.global-footer-container2 {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  align-items: flex-start;
  justify-content: flex-start;
  /* flex-shrink: 0; */
  flex: 1 1;
 width:100% ;
  /* position: relative; */
}


.global-footer-privacy-policy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}

.global-footer-manage-my-privacy {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-legal {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-accessibility {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-product-security {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.global-footer-contact {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}
.copyright-2022-nvidia-corporation {
  color: var(--grayscale-gray-500, #808080);
  text-align: center;
  font: var(--laptop-text-small, 400 11px/140% "NVIDIA Sans", sans-serif);
  /* position: relative; */
}



