.layout {
    overflow: hidden;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .layout > .page-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow: auto;
    /* height minus app bar height */
    height: calc(100% - 3rem);
  }
  .layoutnvbrand
  {
    display: flex;
    /* width: 2560px; */
    padding: 15px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;   
    background: var(--grayscale-black, #000); 
    
height: 60px;

  }

  .layout .content-header {
    flex: 0 0 50px;
  }
  .hubmenu {
    height: auto !important;
  }
  .BrandNavigationheader
  {
    display: flex;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
  }
  .BrandNavigationheader-logo
  {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content-headertypography
  {
    color: var(--grayscale-white, #FFF);
text-align: center;
leading-trim: both;
text-edge: cap;
/* Desktop/Text/Medium */
font-family: NVIDIA Sans;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 166.7%; /* 25.005px */
  }
