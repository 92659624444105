.rolewrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
   grid-column-gap: 6px;
   grid-row-gap:6px;
   word-wrap: break-word;
   overflow: hidden;
 }
 /* Hide the checkbox */
.checkbox-radio {
    display: none;
  }
  
  /* Style the square radio button label */
  .square-radio-label {
    display: inline-block;
    width: 300px; /* Set the width and height for the square */
    height: 30px;
    background-color: #ccc; /* Set the background color for the unchecked state */
    border: 2px solid #aaa; /* Add a border for a square outline */
    border-radius: 4px; /* Optional: Rounded corners */
    text-align: center; /* Center the label content */
    line-height: 30px; /* Center the label content vertically */
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;
  }
  
  /* Change the label appearance when the checkbox is checked */
  .checkbox-radio:checked + .square-radio-label {
    background-color: #70B500; /* Change to the desired color for the checked state */
    border-color: #70B500; /* Change to the desired border color for the checked state */
    color: #fff; /* Change to the desired text color for the checked state */
  }
  