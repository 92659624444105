@font-face {
  font-family: 'NVIDIA-NALA, Arial, Helvetica, sans-serif';
  src: url('../fonts/NVIDIASansVF_NALA_W_Wght.woff2') format('woff2'),
       url('../fonts/NVIDIASans_W_Lt.woff2') format('woff2'),
       url('../fonts/NVIDIASans_W_Lt.woff') format('woff');
 
}
 
html, body {
  font-family: 'NVIDIA-NALA, Arial, Helvetica, sans-serif';    
}