@media (max-width: 2560px)
{
.navigation-enterprise,
.navigation-enterprise * {
  box-sizing: border-box;
}
.navigation-enterprise {
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* align-items: center; */
  /* justify-content: center; */
  
}
.header-container {
  background: var(--grayscale-white, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  /* height: 44px; */
  
}
.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 90%;
  
}
.main-links {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  
}
.group-38 {
  flex-shrink: 0;
  width: 109px;
  height: 20.31px;
  
}
.header-logo {
  width: 109px;
  height: 25px;

  left: 0px;
  top: 0px;
}
.wordmark {

  left: 35.37px;
  top: 3.83px;
  overflow: visible;
}
.eye-mark {

  left: 0.06px;
  top: 0.01px;
  overflow: visible;
}
.user-links {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 234px;
  height: 30px;
  
}

.dashboard-header-site-name {
  color: #ffffff;
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-header-frame-1 {
  background: var(--brand-nvidia-green, #76b900);
  padding: 9px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
 
}
.dashboard-header-primary {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
 
}
}
@media (max-width: 1360px)
{
.navigation-enterprise,
.navigation-enterprise * {
  box-sizing: border-box;
}
.navigation-enterprise {
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* align-items: center; */
  /* justify-content: center; */
  
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 76%;
  
}
.main-links {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  
}
.group-38 {
  flex-shrink: 0;
  width: 109px;
  height: 20.31px;
  
}
.header-logo {
  width: 109px;
  height: 20.31px;

  left: 0px;
  top: 0px;
}
.wordmark {

  left: 35.37px;
  top: 3.83px;
  overflow: visible;
}
.eye-mark {

  left: 0.06px;
  top: 0.01px;
  overflow: visible;
}
.user-links {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 234px;
  height: 30px;
  
}


.dashboard-header-site-name {
  color: #ffffff;
  text-align: center;
  font: var(--laptop-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-header-frame-1 {
  background: var(--brand-nvidia-green, #76b900);
  padding: 9px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
 
}
.dashboard-header-primary {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(
    --laptop-button-text-medium,
    700 14px/125% "NVIDIA Sans",
    sans-serif
  );
 
}

}

@media (max-width: 1024px) 
{
  
    
      .header-site-name {
        color: #ffffff;
        text-align: center;
        font: var(--mobile-text-medium, 400 15px/167% "NVIDIA Sans", sans-serif);
     
      }
    
      .header-frame-1 {
        background: var(--brand-nvidia-green, #76b900);
        padding: 9px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
      }
      .header-primary {
        color: var(--grayscale-black, #000000);
        text-align: center;
        font: var(
          --mobile-button-text-medium,
          700 12px/125% "NVIDIA Sans",
          sans-serif
        );
        position: relative;
      }
   
}
@media (max-width: 640px) 
{
    .header-brand-navigation {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 60px;
        position: relative;
      }
  


      .header-frame-1 {
        background: var(--brand-nvidia-green, #76b900);
        padding: 9px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
      }
      .header-primary {
        color: var(--grayscale-black, #000000);
        text-align: center;
        font: var(
          --mobile-button-text-medium,
          700 12px/125% "NVIDIA Sans",
          sans-serif
        );
        position: relative;
      }
}



.brand-navigation {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  /* min-height: 50px; */
  background: var(--grayscale-black, #000000);
}
.brand-nav {
  /* background: var(--grayscale-black, #000000); */
  /* padding: 15px 0px 15px 0px; */
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 40px;
}
.header-container2 {
  display: flex;
  flex-direction: row;
  /* gap: 30px; */
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 76%;
  
}
.header-container {
  background: var(--grayscale-white, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 30px;
  
}
.header-frame-39 {
  display: flex;
  flex-direction: row;
  /* gap: 30px; */
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  white-space: pre-line; /* Allow text to wrap to the next line */
  
}
.header-site-name {
  color: #ffffff;
  text-align: center;
  font: var(--laptop-heading-small, 700 24px/125% "NVIDIA Sans", sans-serif);
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
  /* height: 36px; */
  
}