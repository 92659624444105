.unauthorized-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #ccc; */
    
    background-repeat: no-repeat;
    /* opacity: 0.5; */
    background-position: center;
  }
  
  .unauthorized-page-content {
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    padding: 20px;
    width: 1200px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  