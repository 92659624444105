
.hide-form
{
display: none;
}


@media (max-width:1360px)
{

.email-picker-container {
   
    margin: auto;
    /* max-width: 1328px; */
    /* min-height: 400px; */
    padding: 24px;
    width: 100%;
}


.email-picker-center-panel {
    border-radius: 5px;
    box-shadow: 0 8px 10px 0 rgba(0,0,0,.25);
    display: flex;
    height: 100%;
    margin: auto;
    overflow: hidden;
    width: 100%;
}


.email-picker-image {
   
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 70%;
}
.email-picker-panel {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    width: 30%;
}
.email-input-text {
    font-size: 14px;
    margin-top: 64px;
    padding: 0 24px;
    width: 100%;
}
.email-input {
    margin-top: 24px;
    padding: 0 24px;
    width: 100%;
}
.email-input-button-container {
    display: flex;
    justify-content: flex-end;
}

}

