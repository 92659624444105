

@media (max-width: 2560px)
{
  .application-hub-4-l,
  .application-hub-4-l * {
    box-sizing: border-box;
  }
  .application-hub-4-l {
    background: #ffffff;
    width: 100%;
  }

.nvod-web-ondemand-hero-bb-460-420-l-2 {
    width: 100%; 
   text-align: center;
   /* height: 400px; */
   justify-content:center;
  
  }
  .dashboard-heading {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    /* width: 400px; */
    margin-left: 198px;

    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    /* left: 198px;
    top: 265px; */
  }
  .dashboard-laptop-heading-huge {
    color: #000000;
    text-align: left;
    font: var(--laptop-heading-huge, 700 48px/125% "NVIDIA Sans", sans-serif);

  }
  .dashboard-text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 391px;
    margin-left: 198px;
    /* left: 198px;
    top: 389px; */
  }
  .dashboard-laptop-text-large {
    color: #000000;
    text-align: left;
    font: var(--laptop-text-large, 400 20px/175% "NVIDIA Sans", sans-serif);
    width: 100%;
    flex: 1;
  }
 
}

@media (max-width: 1360px)
{
  .application-hub-4-l,
  .application-hub-4-l * {
    box-sizing: border-box;
  }
  .application-hub-4-l {
    background: #ffffff;
    max-width: 1360px;
   
  }

.nvod-web-ondemand-hero-bb-460-420-l-2 {
    width: 100%; 
   text-align: center;
   /* height: 400px; */
   justify-content:center;
    /* left: 1px;
    top: 104px; */
  }
  .dashboard-heading {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    
  }
  .dashboard-laptop-heading-huge {
    color: #000000;
    text-align: left;
    font: var(--laptop-heading-huge, 700 48px/125% "NVIDIA Sans", sans-serif);

  }
  .dashboard-text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 391px;
    margin-left: 198px;
    /* left: 198px;
    top: 389px; */
  }
  .dashboard-laptop-text-large {
    color: #000000;
    text-align: left;
    font: var(--laptop-text-large, 400 20px/175% "NVIDIA Sans", sans-serif);
     width: 100%;
    flex: 1;
flex-wrap: wrap;
  }
 
}

@media (max-width: 1024px)
{
  .application-hub-4-l,
  .application-hub-4-l * {
    box-sizing: border-box;
  }
  .application-hub-4-l {
    background: #ffffff;
    width: 100%;
 
    
  }

.nvod-web-ondemand-hero-bb-460-420-l-2 {
    width: 100%; 
   text-align: center;
   /* height: 400px; */
   justify-content:center;
    /* left: 1px;
    top: 104px; */
  }
  .dashboard-heading {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    /* width: 400px; */
    margin-left: 198px;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    /* left: 198px;
    top: 265px; */
  }
  .dashboard-laptop-heading-huge {
    color: #000000;
    text-align: left;
    font: var(--mobile-heading-huge, 500 48px/125% "NVIDIA Sans", sans-serif);

  }
  .dashboard-text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 391px;
    margin-left: 198px;
    /* left: 198px;
    top: 389px; */
  }
  .dashboard-laptop-text-large {
    color: #000000;
    text-align: left;
    font: var(--mobile-heading-huge, 400 20px/175% "NVIDIA Sans", sans-serif);
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
  }
 
}

@media (max-width: 640px)
{
  .application-hub-4-l,
  .application-hub-4-l * {
    box-sizing: border-box;
  }
  .application-hub-4-l {
    background: #ffffff;
    width: 100%;
 
    overflow-x: auto;
  }

.nvod-web-ondemand-hero-bb-460-420-l-2 {
    width: 100%; 
   text-align: center;
   /* height: 300px; */
   justify-content:center;
    /* left: 1px;
    top: 104px; */
  }
  .dashboard-heading {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    /* width: 400px; */
    margin-left: 70px;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    /* left: 198px;
    top: 265px; */
  }
  .dashboard-laptop-heading-huge {
    color: #000000;
    text-align: left;
    font: var(--mobile-heading-huge, 500 25px/100% "NVIDIA Sans", sans-serif);

  }
  .dashboard-text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    /* width: 391px; */
    margin-left: 50px;
    /* left: 198px;
    top: 389px; */
  }
  .dashboard-laptop-text-large {
    color: #000000;
    text-align: left;
    font: var(--mobile-heading-huge, 400 17px/100% "NVIDIA Sans", sans-serif);
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    overflow: hidden;
  }
 
}

.nvod-web-ondemand-hero-bb-460-420-l-2 {
  width: 100%; 
 text-align: center;

 justify-content:center;

}

/* App.css (or a separate stylesheet) */
.centered-message-app {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh; /* Center vertically within the viewport */
}

.centered-message {
  text-align: center;
  background-color: #eee;
  padding: 20px;
  width: 60%;
  border: 1px solid #333;
  border-radius: 5px;
}
.dashboard-laptop-text-large {
  color: #000000;
  text-align: left;
  font: var(--laptop-text-large, 400 20px/175% "NVIDIA Sans", sans-serif);

  flex: 1;
  white-space: pre-line; /* Allow text to wrap to the next line */

}
.dashboard-laptop-heading-huge {
  color: #000000;
  text-align: left;
  font: var(--laptop-heading-huge, 700 48px/125% "NVIDIA Sans", sans-serif);

}